import React, { useState } from 'react'
import Prius from "../assets/img/portfolio/prius1.jpeg";
import Land from "../assets/img/portfolio/land1.jpeg";
import Gtr from "../assets/img/portfolio/gtr1.jpeg";
import Skyline from "../assets/img/portfolio/gtr2.jpeg";
import Lancia from "../assets/img/portfolio/lancia.jpeg";
import BMW from "../assets/img/portfolio/bmw.jpeg";
import Alpha from "../assets/img/portfolio/alpha.jpeg";
import Truck1 from "../assets/img/portfolio/truck.jpeg";
import Truck2 from "../assets/img/portfolio/truck2.jpeg";
import Fuso from "../assets/img/portfolio/fuso.jpeg";
import Komatsu from "../assets/img/portfolio/komatsu.jpeg";
import Kobelco from "../assets/img/portfolio/kobelco.jpeg";
import Crane from "../assets/img/portfolio/crane.jpeg";

// updates on feb 25
import Landcruiser from "../assets/img/new_vehicle/land_silver.jpeg";
import Nbox from "../assets/img/new_vehicle/nbox.jpeg";
import PriusBlack from "../assets/img/new_vehicle/prius_black.jpeg";
import PriusSilver from "../assets/img/new_vehicle/prius_silver.jpeg";
import SuzukiJeep from "../assets/img/new_vehicle/suzuki_jeep.jpeg";
import ToyotaMira from "../assets/img/new_vehicle/toyota_mira.jpeg";
import Tractor from "../assets/img/new_vehicle/tractor.jpeg";

const Gallery = () => {
    const initialData = [
        {type: '車', title: 'トヨタ プリウス', image: PriusBlack},
        {type: '車', title: 'トヨタ プリウス', image: PriusSilver},
        {type: '車', title: 'トヨタ ランドクルーザー', image: Landcruiser},
        {type: '車', title: 'ホンダNボックス', image: Nbox},
        {type: '車', title: 'スズキ ジムニー', image: SuzukiJeep},
        {type: 'インボ/クレーン', title: '', image: Tractor},
        {type: '車', title: 'トヨタ プリウス', image: Prius},
        {type: '車', title: '', image: ToyotaMira},
        {type: '車', title: 'トヨタ ランドクルーザー', image: Land},
        {type: '車', title: '日産 GTR R34', image: Gtr},
        {type: '車', title: '日産スカイライン', image: Skyline},
        {type: '車', title: 'ランチア', image: Lancia},
        {type: '車', title: 'BMW', image: BMW},
        {type: '車', title: 'トヨタ アルファード', image: Alpha},
        {type: 'トラック', title: 'いすゞ ELF', image: Truck1},
        {type: 'トラック', title: 'いすゞ ELF', image: Truck2},
        {type: 'トラック', title: '三菱 扶桑', image: Fuso},
        {type: 'インボ/クレーン', title: '小松', image: Komatsu},
        {type: 'インボ/クレーン', title: '', image: Kobelco},
        {type: 'インボ/クレーン', title: '', image: Crane},
    ];
    const [currentData, setCurrentData] = useState(initialData);

    const [active, setActive] = useState('全て');

    const filter = (category) => {
        if (category !== '全て') {
            
            const filtered = initialData.filter((x) => x.type === category);
            setCurrentData(filtered);
        } else {
            setCurrentData(initialData);
        }
        setActive(category);
    }
    return (
        <section id="portfolio" className="portfolio" style={{marginTop: "-145px"}}>
            <div className="container">
                <h3 className='text-center text-600 mb-3'>ギャラリー</h3>
                <div className="row">
                    <div className="col-lg-12 d-flex justify-content-center">
                        <ul id="portfolio-flters">
                            <li data-filter="*" className={active === '全て' ? 'filter-active' : ''} onClick={() => filter('全て')}>全て</li>
                            <li data-filter=".filter-app" className={active === '車' ? 'filter-active' : ''} onClick={() => filter('車')}>車</li>
                            <li data-filter=".filter-card" className={active === 'トラック' ? 'filter-active' : ''} onClick={() => filter('トラック')}>トラック</li>
                            <li data-filter=".filter-web" className={active === 'インボ/クレーン' ? 'filter-active' : ''} onClick={() => filter('インボ/クレーン')}>インボ/クレーン</li>
                        </ul>
                    </div>
                </div>

                <div className="row portfolio-container">
                    {
                        currentData.map((element, index) => (
                            <div key={index} className="col-lg-4 col-md-6 portfolio-item filter-app">
                                <div className="portfolio-wrap">
                                <img src={element.image} className="img-fluid" alt="image of vehicles" />
                                <div className="portfolio-info">
                                    <h4>{element.title}</h4>
                                    <p>{element.type}</p>
                                    <div className="portfolio-links">
                                    {/* <a href="assets/img/portfolio/portfolio-1.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox" title="App 1"><i class="bx bx-plus"></i></a>
                                    <a href="portfolio-details.html" className="portfolio-details-lightbox" data-glightbox="type: external" title="Portfolio Details"><i class="bx bx-link"></i></a> */}
                                    </div>
                                </div>
                                </div>
                            </div>
                        ))
                    }


                </div>

            </div>
        </section>
    )
}

export default Gallery